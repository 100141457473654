// ion-title {
//   // Adding !important to force precedence in SSR
//   font-weight: 400 !important;
//   letter-spacing: 0.4px !important;
// }

// Override Ionic styles
// .input-clear-icon {
//   // Change these properties so the Ionic close icon aligns with show/hide password
//   &.sc-ion-input-ios,
//   &.sc-ion-input-md {
//     width: calc(1.2rem);
//     margin-inline-start: .5rem;
//   }
// }

.theme-loading {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.8);
  ion-backdrop {
    background-color: #bebebe;
  }
  .loading-wrapper {
    display: block;
    text-align: center;
    background: none;
    box-shadow: 0 0;
    font-size: 19px;
    font-weight: 300;
  }
}

.alert-modal .modal-wrapper {
  --width: 250px;
  --border-radius: 4px;
  --min-height: auto;
  --max-height: 90%;
  --height: 180px;

  ion-footer {
    ion-button {
      font-size: 30px;
    }
  }
}

.font-playfairRegular {
  font-family: PlayFairDisplay !important;
  letter-spacing: 1.3px;
}

.font-newcentury {
  font-family: NewCenturySchool !important;
}
.font-newcenturyItalic {
  font-family: NewCenturyItalic !important;
}
.font-newcentury-school {
  font-family: NewCenturySchool !important;
  line-height: 18px;
}

.font-gotham {
  font-family: gotham !important;
}
.font-gotham-medium {
  font-family: gothamMedium !important;
}
.font-gotham-light {
  font-family: gothamLight !important;
}

.font-STHeitiSC {
  font-family: STHeitiSC !important;
}
.font-STHeitiTC {
  font-family: STHeitiTC !important;
}

.text-light-dark {
  color: rgba(45, 42, 38, 0.85) !important;
  --ion-color-base: rgba(45, 42, 38, 0.85) !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-8 {
  font-size: 18px !important;
}
.fs-7 {
  font-size: 17px !important;
}
.fs-6 {
  font-size: 16px !important;
}
.fs-5 {
  font-size: 15px !important;
}
.fs-4 {
  font-size: 14px !important;
}
.fs-3 {
  font-size: 13px !important;
}
.fs-2 {
  font-size: 12px !important;
}
.fs-1 {
  font-size: 11px !important;
}
.ion-text-underline {
  text-decoration: underline;
}

.alert-wrapper {
  border-radius: 8px !important;
}
// h2#alert-1-hdr {
//   color: #522D6D !important;
//   font-size: 16px !important;
//   text-align: center;
//   font-family: gothamMedium !important;
// }
// .alert-button-group {
//   display: grid !important;
//   justify-content: center !important;
// }
// button.alert-button.sc-ion-alert-md  {
//   margin: 0;
//   background: #522D6D;
//   color: #fff;
//   font-size: 16px;
//   width: 180px;
//   border-radius: 8px;
// }
// span.alert-button-inner.sc-ion-alert-md {
//   display: grid;
//   justify-content: center;
// }

ion-content {
  --padding-start: 10px;
  --padding-end: 10px;
}

.tabs-inner {
  ion-content {
    --background: #f7f9fa;
    --padding-bottom: 100px;
  }
}

app-home .swiper-pagination {
  bottom: 120px !important;
}

.info-alert {
  .alert-message {
    color: var(--ion-color-primary);
    font-family: gothamMedium;
    text-align: center;
  }
  .alert-button-group {
    .alert-button {
      background: var(--ion-color-primary);
      color: #fff;
      margin: auto;
      padding: 12px 22px;
      margin-bottom: 12px;
    }
  }
}

.single-select-alert {
  --min-width: 320px;
  .alert-button-group {
    flex-direction: column;
    align-items: center;
    flex-flow: column-reverse;
    .alert-button {
      border: 1px solid var(--ion-color-primary);
      color: var(--ion-color-primary);
      margin: 6px 0px;
      width: 80%;
      text-align: center;
      font-family: gothamMedium;
      .alert-button-inner {
        justify-content: center;
      }
    }
  }
}
