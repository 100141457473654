// http://ionicframework.com/docs/theming/
// @import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700');
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: STHeitiSC;
  src: url(./assets/fonts/NotoSansSC-Regular.otf);
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  text-transform: none; // mac ff fix
  -webkit-font-smoothing: antialiased; // safari mac nicer
  -moz-osx-font-smoothing: grayscale; // fix fatty ff on mac
  speak: none;
  -webkit-font-smoothing: none !important; //more fatty in safari
  text-rendering: geometricPrecision !important; //more fatty in safari
}

@font-face {
  font-family: STHeitiTC;
  src: url(./assets/fonts/NotoSansTC-Regular.otf);
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  text-transform: none; // mac ff fix
  -webkit-font-smoothing: none; // safari mac nicer
  -moz-osx-font-smoothing: grayscale; // fix fatty ff on mac
  speak: none;
  -webkit-font-smoothing: none !important; //more fatty in safari
  text-rendering: geometricPrecision !important; //more fatty in safari
}

@font-face {
  font-family: "DINPro"; //main font body
  src: url("./assets/fonts/DINPro.otf");
}

@font-face {
  font-family: "DINProBold"; //main font body
  src: url("./assets/fonts/DINPro-Bold.otf");
}

@font-face {
  font-family: PlayFairDisplay;
  src: url(./assets/fonts/PlayfairDisplay-Regular.ttf);
}

// $purple: #522d6d;
@font-face {
  font-family: NewCentury;
  src: url(./assets/fonts/NewCenturySchlbk-Normal.ttf);
}

@font-face {
  font-family: NewCenturyItalic;
  src: url(./assets/fonts/NewCenturySchlbk-Italic.ttf);
}

@font-face {
  font-family: NewCenturySchool;
  src: url(./assets/fonts/New_Century_Schoolbook.ttf);
}

@font-face {
  font-family: gotham;
  src: url(./assets/fonts/Gotham_Book_Regular.otf);
}

@font-face {
  font-family: gothamMedium;
  src: url(./assets/fonts/Gotham_Medium.otf);
}

@font-face {
  font-family: gothamLight;
  src: url(./assets/fonts/Gotham_Light.otf);
}

@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// EXTRA GLOBAL STYLES
// Add custom Ionic Colors
@import "theme/custom-ion-colors.scss";
// Add base app styles
@import "theme/app-defaults.scss";
// Add base shell styles
@import "theme/shell-defaults.scss";